

























































































































































import Vue from 'vue';
// import LookupSiHaDocumentationStatusRequestViewModel from '@/src/services/viewModel/request/SiHaDocumentationStatus/LookupSiHaDocumentationStatusRequestViewModel';
import GetSafetyAnchorPointsBySiHaDocumentationRequestViewModel from '@/src/services/viewModel/request/SiHaSafetyAnchorPoint/GetSafetyAnchorPointsBySiHaDocumentationRequestViewModel';
// import Int64LookupResourceViewModel from '@/src/services/viewModel/resource/Int64LookupResourceViewModel';
import SafetyAnchorPointListResourceViewModel from '@/src/services/viewModel/resource/SafetyAnchorPointListResourceViewModel';
import SafetyHookResourceViewModel from '@/src/services/viewModel/resource/SafetyHookResourceViewModel';
import ShareSiHaDocumentationRequestViewModel from '@/src/services/viewModel/resource/ShareSiHaDocumentationRequestViewModel';
import SiHaDocumentationResourceViewModel from '@/src/services/viewModel/resource/SiHaDocumentationResourceViewModel';

export default Vue.extend({
  auth: true,
  props: {
    value: { type: Object, default: undefined },
    callSaveServiceFunction: { type: Function, default: () => (() => {}) },
  },
  data: () => ({
    isDownloadLoading: false,
    isEmailSendLoading: false,
    shareSiHaDocumentation: new ShareSiHaDocumentationRequestViewModel(),
    isLoading: false,
    safetyHooks: [] as SafetyHookResourceViewModel[],
    anchorPoints: [] as SafetyAnchorPointListResourceViewModel[],
    checked: [] as boolean[],
    requestError: undefined as any,
    emailWasSent: false,
    // siHadocumentationStatuses: [] as Int64LookupResourceViewModel[],
    statusItems: [
      { caption: Vue.$t('page.siHaDocumentationOverview.select.status.1'), id: 1 },
      { caption: Vue.$t('page.siHaDocumentationOverview.select.status.2'), id: 2 },
      { caption: Vue.$t('page.siHaDocumentationOverview.select.status.3'), id: 3 },
      { caption: Vue.$t('page.siHaDocumentationOverview.select.status.4'), id: 4 },
      { caption: Vue.$t('page.siHaDocumentationOverview.select.status.5'), id: 5 },
    ],
  }),
  computed: {
    mobileShareLink(): string {
      const { tenant } = this.$router.currentRoute.params;
      const { workspace } = this.$router.currentRoute.params;
      return `${window.location.origin}/${tenant}/${workspace}/siha/mobile/documentation/${this.innerValue.shareId}`;
    },
    qrCodeEnabled(): boolean {
      return this.$config.values['siha-qrcode-enabled'];
    },
    qrcodeUrl(): string {
      return `${this.$config.values['siha-qrcode-generator-url']}${this.mobileShareLink}`;
    },
    siHaDocumentationId() {
      const id = this.$router.currentRoute?.params?.id as string | undefined;
      if (id) {
        return Number.parseInt(id, 10);
      }
      return undefined;
    },
    innerValue: {
      get(): SiHaDocumentationResourceViewModel {
        return this.value ?? {};
      },
      set(value: SiHaDocumentationResourceViewModel) {
        this.$emit('input', value);
      },
    },
  },
  async mounted() {
    // await this.loadSiHaDocumentationStatuses();
    await this.loadSafetyHooks();
    await this.populateData();
    if (!this.innerValue.nextInspectionDueDate) {
      this.innerValue.nextInspectionDueDate = this.$date.now().add(1, 'years').format('YYYY-MM-DD');
    }
  },
  methods: {
    async onSendSiHaDocumentationPerEmail() {
      this.isEmailSendLoading = true;
      this.shareSiHaDocumentation.shareLink = this.mobileShareLink;
      if (this.shareSiHaDocumentation.sendToEmailAddress) {
        try {
          await this.$service.api.siHaDocumentation.shareSiHaDocumentation(this.siHaDocumentationId!, this.shareSiHaDocumentation);
          this.emailWasSent = true;
          setTimeout(() => {
            this.emailWasSent = false;
          }, 7000);
        } catch (error) {
          this.requestError = error;
        }
      }
      this.isEmailSendLoading = false;
    },
    async onSiHaDocumentationDownload() {
      this.isDownloadLoading = true;
      await this.callSaveServiceFunction(); // save data first
      const response = await this.$service.api.siHaDocumentation.downloadSiHaDocumentation(this.siHaDocumentationId!);
      const created = this.$format.date(this.innerValue.created!, 'YYYY-MM-DD');
      const fileName = `${created}_${this.innerValue.title}.pdf`;
      this.$service.utils.downloadFile(fileName, response);
      this.isDownloadLoading = false;
    },
    resolveSafetyHookName(id: number): string {
      const usedSH = this.safetyHooks.find((sh: SafetyHookResourceViewModel) => sh.id === id);
      return usedSH?.name ?? '...';
    },
    async loadSiHaDocumentationStatuses() {
      // unused because the backend does not yet support translations
      // the statusies are loaded from translation-file.
      // see data -> statusItems
      // // // this.isLoading = true;
      // // // const req = new LookupSiHaDocumentationStatusRequestViewModel();
      // // // const response = await this.$service.api.siHaDocumentationStatus.lookupSiHaDocumentationStatus(req);
      // // // this.siHadocumentationStatuses = response.items;
      // // // this.isLoading = false;
    },
    async loadSafetyHooks() {
      this.isLoading = true;
      const response = await this.$service.api.siHaSafetyHook.getSafetyHookList();
      this.safetyHooks = response.items;
      this.isLoading = false;
    },
    async populateData() {
      this.isLoading = true;
      const req = new GetSafetyAnchorPointsBySiHaDocumentationRequestViewModel();
      req.sihaDocumentationId = this.siHaDocumentationId;
      const response = await this.$service.api.siHaSafetyAnchorPoint.getSafetyAnchorPointsBySiHaDocumentation(req);
      this.anchorPoints = response.items;
      this.isLoading = false;
    },
    async printQRCode(id : number) {
      // open page printQRCode in new window..
      const routeData = this.$router.resolve(`/glaromat/il/siha/documentation/${id}/qrcode`);
      window.open(routeData.href, '_blank');
    },
  },
});
