// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import GetSafetyAnchorPointsBySiHaDocumentationRequestDTO from '../../dto/request/GetSafetyAnchorPointsBySiHaDocumentationRequestDTO';

export default abstract class GetSafetyAnchorPointsBySiHaDocumentationRequestModel extends BaseModel<GetSafetyAnchorPointsBySiHaDocumentationRequestDTO> {
  // #region    ──BodyRequest──────────────────────────╮
  // #endregion ───────────────────────────────────────╯
  // #region    ──HeaderRequest──────────────────────────╮
  // #endregion ─────────────────────────────────────────╯
  // #region    ──QueryRequest──────────────────────────╮
  /**
  * @type {int64}
  */
  get sihaDocumentationId() { return this.dto.sihaDocumentationId; }
  set sihaDocumentationId(value) { this.dto.sihaDocumentationId = value; }
  // #endregion ────────────────────────────────────────╯

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    value = Number.parseFloat(queries.sihaDocumentationId);
    value = Number.isNaN(value) ? undefined : value;
    this.dto.sihaDocumentationId = value;
  }
}
