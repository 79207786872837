// ╭────────────────────────────────────────────────────────────────────────────────────╮
// ┊  This file was automatically generated
// ┊  Please do not modify manually
// ┊  ©gl it 2022 - http://www.gl-it.ch
// ╰────────────────────────────────────────────────────────────────────────────────────╯

import BaseModel from '@glittr/frontend-core/src/plugins/servicelayer/serviceTypes/baseModel';
import ShareSiHaDocumentationRequestDTO from '../../dto/resource/ShareSiHaDocumentationRequestDTO';

export default abstract class ShareSiHaDocumentationRequestModel extends BaseModel<ShareSiHaDocumentationRequestDTO> {
  /**
  * @type {email}
  */
  get sendToEmailAddress() { return this.dto.sendToEmailAddress; }
  set sendToEmailAddress(value) { this.dto.sendToEmailAddress = value; }
  /**
  */
  get shareLink() { return this.dto.shareLink; }
  set shareLink(value) { this.dto.shareLink = value; }

  protected transformFromQuery(queries: Record<string, string>): void {
    let value: any;
    this.dto.sendToEmailAddress = queries.sendToEmailAddress;
    this.dto.shareLink = queries.shareLink;
  }
}
